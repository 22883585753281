@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.animate-updown {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    color: rgb(241, 13, 51);
  }
  50% {
    opacity: 0;
  }
  100% {
    color: rgb(241, 13, 51);
  }
}


.image-download:hover .download-btn {
  display: block;
}
.download-btn {
  display: none;
}
