.mainBG{
  width: 100%;
  height: 400px;
  background-size: cover; 
  background-position: top;
}
.ds{
  height: 600px;
}
.mw{
  max-height: 151px;
  overflow: hidden;
  position: relative;
}
/* Tailwind CSS classes can be used directly here */
input,
select {
  border: 1px solid #e8e8e8 !important;
  border-color: #e0e0e0 !important;
}
.search input{
  border:none  !important
}
.tds {
  background: #fff !important;
  border-radius: 43px !important;
  box-shadow: 1px 1px 8px #00000063 !important;
  padding-bottom: 60px !important;
  margin-bottom: 50px !important;
  max-width: 1024px !important;
}
.serarch_label {
  --tw-text-opacity: 1;
  color: #282626;
  background: #f8fafc;
  width: 100px;
  border: 1px solid #665d5d;
  padding: 5px 9px;
  text-align: center;
  transform: translateY(-5px);
  border-radius: 22px;
}
.border-slate-200\/80 {
  border-color: rgb(153 153 153 / 80%);
}
.no-border {
  background: transparent;
  border: none !important;
  outline: none;
}
.no-border:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
.bgd{
  font-size: 10px;
  background-color: #2271B1;
}
.xxs{
  font-size: 12px;
}
.full-height{
  height: 50vh;
}
.today{
  background-color: #cfedff;
}
.selectedDay {
    background-color: #2271b1;
}
.selectedDay p {
    color: #fff !important
}
.bdg{
    border-radius: 30px;
    width: 20px;
    height: 20px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    transform: translateX(-6px);
    border: 1px solid #ddd;
    background: #fff;
}

.mx-ht{
  max-height:115px;
  overflow:hidden
}
.rd{
  width: 10px;
  height: 10px;
  border-radius: 50px;
  display: block;
}
.ml-l{
      border-left: 10px solid #787070;
    padding-left: 17px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    margin-bottom: 20px;

}
.bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: #2271B1;
}
.dots{
  background-color: #000;
  width: 7px;
  height: 7px;
  border-radius: 20px;
}
.no-border{
  border: none !important;
}